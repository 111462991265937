import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function FssaiLicense() {
  /* Slider */
  // const FoodSlider = [
  //   {
  //     content_color: true,
  //     header_color: true,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Online FSSAI License Registration Service`,
  //     content: `Online Food License Registration Service In Hosur`,
  //     image: "/imgs/registration/fssai/fssai-slider.png",
  //     alt_tag: "Best Online FSSAI Registration Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `FSSAI license`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    f_price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const FssAiAboutData = {
    header: `FSSAI Registration Online`,
    sub_title: `FSSAI license registration online in India within 7 days.`,
    content_paragraph: [
      `The FSSAI license, commonly known as the food license, is provided by the food licensing and registration system and it
       determines the quality of the food. A person with a food license is only permitted to sell his own food. According to Section
       31(1) of the FSS Act of 2006, food licensing is required in India for all food business operators.`,

      // `Even before placing an order for food, you can get your FSSAI license! By issuing producers
      //  a license for food service businesses, the Food Safety and Standards Authority of India.`,
    ],
  };

  /* Scroll Nav Data */
  const FssAiScrollId = [
    {
      id: `#types`,
      heading: `Food License Types`,
    },
    {
      id: `#procedure`,
      heading: `Registration Procedure`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
    {
      id: `#renewal`,
      heading: `Renewal`,
    },
    {
      id: `#penalty`,
      heading: `Penalty`,
    },
  ];

  /* Img Content Component Data */
  const FssAiIcData = {
    id: 'types',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Types Of Food Safety License',
    image: '/imgs/registration/fssai/fssai-types.png',
    alt_tag: 'Types of Food safety license',
    points: [
      {
        head: 'Basic FSSAI Registration',
        content: `A food license is required for all food-related enterprises,
      There are essentially two categories of FSSAI
        registration, license, or food safety registration. The
        FSSAI Registration is the other option; the first being the
        FSSAI License. But how can you choose the one that's best
        for you?`,
        icon: true,
      },
      {
        head: '',
        content: `Petty food business entrepreneurs need a basic FSSAI
        Registration. The annual revenue of such food-related
        businesses, such as petty retailers, itinerant vendors,
        hawkers, or temporary stallholders, shall not exceed Rs. 12
        lakhs.`,
        icon: true,
      },
      {
        head: 'FSSAI State License',
        content: `A business that
        has a revenue of between Rs. 12 Lac and Rs. 20 Cr. is
        granted an FSSAI State License. An FSSAI State Manufacturing
        licence and an FSSAI State Trading licence both have the
        same turnover cap.`,
        icon: true,
      },
      {
        head: 'Central FSSAI License',
        content: `A licence called
        a Central FSSAI License Registration is given to food
        companies with a revenue of more than Rs. 20 crore.
        Manufacturing, trading, and storage facilities, among other
        FBOs, fall within this category.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const FssAiCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Procedure To Obtain The FSSAI License In India',
    content: ``,
    body: [
      {
        head: 'Basic Registration',
        points: `After the relevant authorities has verified all of your documents, the next step is to generate your
        login ID. After that, you must submit a properly completed Form A for approval together with the required costs.
         Depending on where your firm is located, the concerned will grant your license within 10 to 15 days.`,
        icon: true,
      },
      {
        head: 'State License',
        points: `Your login ID is created at this stage, which comes after all of your documents have been verified by
        the appropriate authority. A specific document called the "Declaration" must also be filed with the appropriate
        Form in order to be granted a state licence. Additionally, the "Declaration" contains the operator's general
        declaration statement that has been signed, together with the required Form 9, Proclamation of Food Safety
        License Management System FSMS, and declaration that is specific to the type of company that the operator
        conducts.`,
        icon: true,
      },
      {
        head: '',
        points: `The procedure must then be completed by filing Form B together with the required paperwork.
        Depending on where the firm runs, you'll have your license in 20 to 30 days.`,
        icon: true,
      },
      {
        head: '',
        points: `However, you must revise your application if it contains any mistakes or other problems, and you have
        thirty days to submit it. Otherwise, your application will be rejected, and you'll have to start over from
        square one.`,
        icon: true,
      },
      {
        head: 'Central License',
        points: ` The steps for acquiring a Central License are the same as those for a State License.
        The FBO's kind of food company is the only distinction. And the time needed for the Central License granting procedure is likewise comparable.`,
        icon: true,
      },
      {
        head: '',
        points: `Regardless of the company's annual revenue, an IEC, or Import Export Code, is required if the operator
        of the food business is involved in the export or import of food products. Similar to this, a central license is
         necessary if the food business transports food goods. This license must specifically state that FBO is engaged
         in this food industry.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const FssAiCiData = {
    id: 'renewal',
    background: 'main-content',
    mt_div: 'mt-1',
    header: 'Renewal of FSSAI License',
    paragraph: `.`,
    points: [
      {
        head: '',
        content: `The FSSAI Act 2006 defines the norms and regulations that all food business operators must abide by.
        Every proprietor or operator of a food business must obtain an FSSAI license. `,
        icon: true,
      },
      {
        head: '',
        content: `And the majority of people are unaware that maintaining your FSSAI license is just as crucial
        as obtaining one. The FSSAI license must be renewed within the allotted time frame.`,
        icon: true,
      },
      {
        head: '',
        content: `Whether your food business is all about processing units, manufacturing
          units, distribution units, food packaging units, or trading units, it is imperative that you receive food
          certificates online every one to five years.`,
        icon: true,
      },
      {
        head: '14 Digit FSSAI License Number',
        content: `increases consumer happiness since ISO standards force businesses to provide the highest calibre goods and services
        to their clients.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/fssai/fssai-renewal.png',
    alt_tag: 'Benefits for Iec Registration',
  };

  /* Img Content Component Data */
  const FSSAIIcData = {
    id: 'penalty',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Penalty For Selling Food Without A License',
    image: '/imgs/registration/fssai/fssai-penalty.png',
    alt_tag: 'Penalty for selling food',
    Paragraph: `All registered FBOS, or food business operators, are given the 14-digit FSSAI license,
    which is divided into five sections and contains several pieces of information about the FBO. The FSSAI
    emblem and introductory information about the license shall be displayed on the food item's packaging.`,
    points: [
      {
        content: `Food quality not in compliance with the Act would result in fines of maximum Rs. 2 Lakhs and minimum
         Rs. 25 000 for small producers.`,
        icon: true,
      },
      {
        content: `5 lakhs of rupees for mis branded food products`,
        icon: true,
      },
      {
        content: `3 Lakhs for inferior or poor quality food products`,
        icon: true,
      },
      {
        content: `Rs. 10 Lakhs for dishonest promotion, deceptive advertising, or false description`,
        icon: true,
      },
      {
        content: `For unimportant details pertaining to the food product: Rs. 1 Lakh`,
        icon: true,
      },
      {
        content: `2 lakh rupees will be assessed for disregarding the food safety officer's instructions.`,
        icon: true,
      },
      {
        content: `For processing, production, or preparation of food using unsanitary methods: Rs.`,
        icon: true,
      },
    ],
  };

  // FAQ-DATA
  var FssaiLicenseFAQ = [
    {
      header: 'Do I need an FSSAI License to import food products in India?',
      body: [
        {
          content: `Yes, your company does require an import-export code from the FSSAI
          central licencing. All food goods that are imported or exported must
          have an FSSAI license.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is enforcement of FSS Act, 2006? ',
      body: [
        {
          content: `Responsibility of ensuring compliance of FSS Act, 2006 rules and regulations made thereunder by the FBO’s`,
          icon: false,
        },
      ],
    },
    {
      header: 'How is Enforcement undertaken?',
      body: [
        {
          content: `Enforcement is undertaken on the basis of surveillance, monitoring, inspection and random
          sampling of food products carried out by the Officials of Food Safety Departments`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is Improvement Notice & who issues improvement notice? ',
      body: [
        {
          content: `The improvement notice is the notice issued by Designated Officer if FBO fails to
          comply with the regulations as mentioned in Section 32 of the FSS Act, 2006.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Under what circumstances can the DO suspend the license?',
      body: [
        {
          content: `If the food business operator fails to comply with an improvement notice, his licence may be suspended.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Where can consumers find information on enforcement activities of FSSAI? ',
      body: [
        {
          content: `The details are available on FSSAI’s Official website:http://www.fssai.gov.in/home/compliance/compliance-orders.html`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header:
        'Do I require the FSSAI License if I only manufacture food items?',
      body: [
        {
          content: `Yes, all food-related enterprises, including those engaged in
          trading, transportation, production, or distribution of food
          products, require an FSSAI license.`,
          icon: false,
        },
      ],
    },
    {
      header: "Is there any requirement to file an annual return for FBO's?",
      body: [
        {
          content: `Yes, certain FBO's defined by law need to file an annual return in
          the specified manner.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: "What is food 'recall'?",
      body: [
        {
          content: `You will have statewide protection for your business.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'How to obtain a FSSAi License or food license for a restaurant?',
      body: [
        {
          content: `A restaurant or catering service can obtain a food licence using the
          same procedures as other licences.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Fssai License'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={FoodSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={FssAiAboutData} />

          <Counter />

          <ScrollNav scroll_data={FssAiScrollId} />

          <ImgContent item={FssAiIcData} />

          <ContentForm CFSection_data={FssAiCfData} />

          {/* ======================Documents====================== */}
          <section id='documents' className='mt-5 mb-0'>
            <div className='container'>
              <header className='section-header mt-5'>
                <h2>Documents needed for FSSAI Registration in India</h2>
              </header>
              <div className='row'>
                {/* ==============1===================== */}
                <div className='col-md-6 '>
                  <h4>Documents Needed For Central FSSAI License</h4>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Filled and duly
                    signed Form B
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Blueprint of the food licensing business unit
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>List of equipment
                    and machinery used in the business unit
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Details of
                    partners, directors, proprietors, and so on as per the type
                    of entity
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>List of food
                    products or food items to be dealt with
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Sources of raw
                    materials used in the production process
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Form IX
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    MOA, Partnership deed, AOA, etc. are required as per the
                    type of entity.
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Copy of
                    certificate from the Ministry of Tourism
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Copy of
                    certificate from Ministry of Commerce for 100% EOU
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Rent agreement is
                    required if the premise is rented place
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>NOC or No
                    Objection Certificate issued by FSSAI
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Authority Letter
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Declaration Form
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>NOC from
                    Manufacturer and a copy of the license
                  </p>
                </div>
                {/* ==============2===================== */}
                <div className='col-md-6'>
                  <h4>Documents Required For FSSAI State License</h4>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Completed and duly signed Form B
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Blueprint of Food business unit
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>List of equipment
                    and machinery used in the unit
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Complete details of partners, proprietors, directors, etc.
                    as per the type of entity
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>List of food
                    products or food items to be dealt with
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Authority Letter
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Proof of
                    possession of the premise such as rent agreement.
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Certificate of Food Safety Management System
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Any other needed
                    document
                  </p>
                  <h4>Additional Documents Needed For Manufacturers</h4>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>The layout
                    blueprint of the processing unit
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>
                    Complete details of machinery or equipment of the unit
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Details of food
                    license online categories that is to be produced or
                    manufactured
                  </p>
                  <p>
                    <i className='bi bi-check-circle-fill'></i>Report for
                    pesticide residues on the water
                  </p>
                </div>
              </div>
            </div>
          </section>

          <ContentImg CISection_data={FssAiCiData} />

          <ImgContent item={FSSAIIcData} />
          <Cta />

          <Guidance />

          <FAQAccord items={FssaiLicenseFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
